import React,  { Component, useState, useEffect }from 'react';
import { BrowserRouter , Link, NavLink, useNavigate, withRouter, useParams  } from 'react-router-dom';
import './Style.css';
import TextField from '@mui/material/TextField';
import $ from 'jquery'; 

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';




function ValidateEmail() {
  var email = document.getElementById("txtEmail").value;
  var lblError = document.getElementById("lblError");
  lblError.innerHTML = "";
  var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  if (!expr.test(email)) {
      lblError.innerHTML = "Invalid email address.";
  }
}

function Validcoupon() {

  
  console.log(window.location.href);



 const { jvVchNo } = useParams([]);

    
 const [offdata2, setOffData2] = useState([]);

 const offdata1 = {
   parentID: "40046",
   jvVchNo:'jvVchNo'
 };
     
 const urlData='https://api.dialerp.com/api/WebApi/getInfluDetails/'+jvVchNo+'?pipelineID=1';
 
 function Offdata() {  

   fetch(
       urlData,
     {
       method: "POST",
       headers: {
         
         Accept: "application/json, text/plain",
         "Content-Type": "application/json;charset=UTF-8",
       },
       //body: JSON.stringify(offdata1),
     }
   ).then((result) => {

     result.json().then((resp) => {
      // console.log(resp, 'datas');
       if(resp.hasErrors){
         window.setTimeout(function() {
            window.location.href = '/not-found';
       }, 500);
       } 
       else
       setOffData2(resp);
       
      //const scanHistoryRows= resp.response.objLst.length;
       // if ( scanHistoryRows < 1 && scanHistoryRows == 0 ){

        //  window.setTimeout(function() {
          //  window.location.href = 'not-found';
        //}, 1
         // ); 
         // alert('Data Not Find')
       // }

    // console.log(offdata1, 'data');

       
       //const name = resp.response.searchTxt;
       //console.log(name, 'Id');

     });
   });
 }
 
 useEffect(() => {
  Offdata();
 // setTimeout(()=>{
    //setOpen(true)
  //}, 500)

},[]);
 
//debugger;
const [txtFirstName, setName] = useState("");
const [txtEmail, setEmail] = useState("");
const [txtMobile, setMobileNumber] = useState("");
const [txtMessage, setMessage] = useState("");
const [setAgent] = useState("");
const [txtMainGoal, setMaingoal] = useState("");
const [txtSubGoal, setSubgoal] = useState("");
const [txtTargetyear, setTargetyear] = useState("");
const [txtCity, setCity] = useState("");
const [txtcollege, setCollege] = useState("");
const [txtStatus, setStatus] = useState("");
const [txtLastYear, setLastYear] = useState("");
const [txtParentName, setParentName] = useState("");
const [txtParentMobile, setParentMobile] = useState("");
const [ setagMobile] = useState("");
const [txtqrCode, setqrCode] = useState("");
const [setLocation] = useState("");
const [setReport] = React.useState('');   
var txtReport = offdata2?.response?.searchTxt;
var txtAgent = offdata2?.response?.objLst[0]?.influencerMobile;
var agMobile = offdata2?.response?.objLst[0]?.influencerMobile;

var txtqrCodes =  offdata2?.response?.objLst[0]?.jvVchNo;

//console.log(txtqrCodes, 'dsdsd')
var txtLocation = '.';
//var txtParentName = '.';

var crmForms = "custom1_SelectMainGoal(Dropdown)=" + $('#txtMainGoal').val() + "," +"custom2_Prefertimeforcall(Dropdown)=" + $('#txtSubGoal').val() + "," +"custom3_AgentMobileNumber(Numeric)=" +$('#agMobile').val() + "," +
"custom4_YourCityName(Dropdown)="+$('#txtCity').val() + "," +
"custom5_InstitutionName(Text)="+$('#txtcollege').val() + "," +"custom6_CurrentlyStudyingIn(Dropdown)="+$('#txtStatus').val() + "," + "custom7_EventLocation(Text)="+$('#txtLocation').val() + "," + "custom8_Enteryourcomment(Text)="+$('#txtParentName').val() + "," + "custom9_AlternateMobileNumber(Numeric)="+$('#txtParentMobile').val() + "," + "custom10_QRCode(Text)="+$('#txtqrCode').val();




let handleSubmit = async (e) => {
  e.preventDefault();
   // e.target.reset();
 fetch
   ("https://api.dialerp.com/api/WebApi/pushCRMDealMongo/40046A1?countryCode=91&mobile=" +txtMobile+"&name="+txtFirstName+"&emailID="+txtEmail+"&remarks="+txtMessage+"&crmForms="+crmForms, {
    method: 'POST',  
    headers: {
         
      Accept: "application/json, text/plain",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      response: "Success"
    }),
  } ).then((result) => {
    result.json().then((resp) => {
      console.log(resp);
    
      if(resp.includes("Success")){
       window.location.href = "/thankyou";
       
     }
     if(resp.includes("Failed")){
        window.location.href = "/tryagain"
      }
    
      clearData();

    });
  })
  .catch((error) => {
      console.log(error)
  })
} 


function clearData() {
  $('#txtFirstName').val('');
  $('#txtEmail').val('');
  $('#txtMobile').val('');
  $('#txtMessage').val('');
  $('#agMobile').val('');
  $('#txtcollege').val('');
  $('#txtLocation').val('');
  $('#txtParentName').val('');
  $('#txtParentMobile').val('');
  $('#txtqrCode').val('');
}

{/*

  var locations = {
      'SSC Exams (Non Technical/Railway Exams)': ['SSC CPO', 'SSC MTS', 'SSC Stenographer Exam', 'SSC GD Constable', 'SSC CHSL', 'SSC CGL'],
      'Bank Entrance Exams': ['Bank Regulatory Body Exam','Bank Clerk','SBI PO','JAIIB Exams','Insurance Exams','Food Corporation of India (FCI)','CAIIB','Bank SO Exams','Bank Regulatory Body Exams','Bank Exams'],

      'UPSC CSE': ['UPSC CSAT','UPSC CSE -Optional','UPSC CSE-GS' ],

      'CLAT and Other Law Exams': ['CLAT and Other Law Exams'],

      'Judiciary': ['Lawyer','Student Fifth Year','Student  Fourth Year','Student  Third Year','Student Second Year','Student First year'],

      'CA,CS': ['CSEET', 'CA Executive - Module 2', 'CS Executive - Module 1', 'CA Final Group 2', 'CA Final Group 1', 'CA Intermediate', 'CA Foundation'],

      'NEET PG': ['NEET PG'],

      'JOB PLACEMENT COURSES FOR ENGINEERS': ['Other', 'Mechanical', 'Electrical', 'ECE', 'Civil'],

      'CAT & Management Exams': ['PI & WAT for CAT & OMATS', 'GD', 'CAT & MBA Entrance Test'],

      'GATE ,ESC & IIT JAM': ['IIT-JAM', 'SSC JE & State AE Exams', 'GATE - CS & IT', 'GATE & ESE - EE & EC', 'GATE & ESE - CIVIL', 'GATE & ESE-ME&CH'],


      'IIT JEE': ['IIT JEE'],

      'NEET UG': ['NEET UG'],

      'CBSE Class 12': ['CBSE Class 12'],

      'CBSE Class 11': ['CBSE Class 11'],

      'CBSE Class 10': ['CBSE Class 10'],

      'CBSE Class 9': ['CBSE Class 9'],

      'CBSE Class 8': ['CBSE Class 8'],

      'Offline Center UPSC': ['Offline Center UPSC'],

      'Offline Center IIT JEE': ['Offline Center IIT JEE'],

      'Offline Center NEET UG': ['Offline Center NEET UG'],

      'MBA, MCA': ['Manipal', 'Amity'],
      'M.SC, M.Com, MA': ['Manipal', 'Amity'],
      'BBA, BCA': ['Manipal', 'Amity'],
      'B.SC, B.Com, BA': ['Manipal', 'Amity'],
      'Data Scientist': ['Simplilearn'],
      'Data Analytics': ['Simplilearn'],
      'Artificial Intelligence Engineer': ['Simplilearn'],
      'Full Stack Developer MEAN Stack': ['Simplilearn'],
      'Automation Test Engineer': ['Simplilearn'],
      'Digital MArketing Specialist': ['Simplilearn'],
  }
  
  var $locations = $('#txtSubGoal');
  $('#txtMainGoal').change(function () {
      var country = $(this).val(), lcns = locations[country] || [];
      
      var html = $.map(lcns, function(lcn){
          return '<option value="' + lcn + '">' + lcn + '</option>'
      }).join('');
      $locations.html(html)
  });
*/}
  $(document).on('keypress', '#txtFirstName', function (event) {
    var regex = new RegExp("^[a-zA-Z ]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
        event.preventDefault();
        return false;
    }
});

{/* }
$(document).on('keypress', '#txtParentName', function (event) {
  var regex = new RegExp("^[a-zA-Z ]+$");
  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!regex.test(key)) {
      event.preventDefault();
      return false;
  }
});
*/}

$(document).on('keypress', '#txtcollege', function (event) {
  var regex = new RegExp("^[a-zA-Z ]+$");
  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!regex.test(key)) {
      event.preventDefault();
      return false;
  }
});


  return (
  <div>
    
  <section className="login">
  <div className="login_box">
      <div className="contact">
          <form className="example-form" onSubmit={handleSubmit}>
            <div className='logobox'>
               <img src={process.env.PUBLIC_URL + './images/logo.svg'} alt="Slide" className='logo' />
             </div>

              <h3>Learner Registration</h3>
          
             <p>Thank you for showing interest! <br/>
             Please fill the basic details to complete your registration.</p>


               <div className="inputbox">
                <div className='sabel'>
                    <label>Name*</label>
                <TextField id="txtFirstName" className='inputs' variant="standard"  value={txtFirstName} onChange={(e) => setName(e.target.value.replace(/[^a-z]/gi, ' '))} name="name" required />
                  </div>
                  <div className='sabel'>
                    <label>Mobile*</label>
               <TextField id="txtMobile" variant="standard" className='inputs' name="mobile"  value={txtMobile} onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ""))} required type="tel" inputProps = {{minLength:10, maxLength:10}}  />
                </div>
                </div>


               <div className="inputbox">
               <div className="sabel">
               <label className="">Email Id*</label><span id="lblError" ></span>
                <input className="did-floating-input" type="email" placeholder=" " onKeyUp="ValidateEmail();"  value={txtEmail}  onChange={(e) => setEmail(e.target.value)} id='txtEmail' required  />
               
              </div>
           
              <div className="sabel">
              <label>City*</label>
              <select name="txtCity" id="txtCity" required>
                     <option></option>
                      <option value="Ahmedabad">Ahmedabad</option>
                      <option value="Allahabad">Allahabad</option>
                      <option value="Bhopal">Bhopal</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Gurgaon">Gurgaon</option>
                      <option value="Hyderabad">Hyderabad</option>
                      <option value="Indore">Indore</option>
                      <option value="Jaipur">Jaipur</option>
                      <option value="Lucknow ">Lucknow </option>
                      <option value="Ludhiana">Ludhiana</option>
                      <option value="Noida ">Noida </option>
                      <option value="Patna">Patna</option>
                    </select>
                    </div>
                </div>


                  <div className='inputbox'>
                  <div className="sabel">
              <label>Main Goal*</label>
            
                      <select id="txtMainGoal" name="country" placeholder="Phantasyland" required>
                        <option></option>
                             {/*<option>SSC Exams (Non Technical/Railway Exams)</option>
                          <option>Bank Entrance Exams</option>
                          <option>UPSC CSE</option>
                          <option>CLAT and Other Law Exams</option>
                          <option>Judiciary</option>
                          <option>CA,CS</option>
                          <option>NEET PG</option>
                          <option>CAT & Management Exams</option>
                          <option>GATE ,ESC & IIT JAM</option>
                          <option>IIT JEE</option>*/}
                         {/* <option>NEET UG</option>
                          <option>CBSE Class 12</option>
                          <option>CBSE Class 11</option>
                          <option>CBSE Class 10</option>
                          <option>CBSE Class 9</option>
                          <option>CBSE Class 8</option>
                          <option>Offline Center UPSC</option>
                          <option>Offline Center IIT JEE</option>
                          <option>Amazon Web Service ( AWS)</option>
                          <option>Cyber Security</option>
                          <option>Certification Program in Python Full Stack Development</option>
                          <option>Certification Program in Java Full Stack Development</option>
                          <option>Certificate Program in Data Analytics with Business Intelligence</option>
                          <option>Information Systems Security and Cloud Security Professional</option>
                          <option>Advanced Certification Program in Cloud Data Engineering</option>
                          <option>Certificate Program in Big Data analytics with hadoop</option>
                        
                          <option>Advance general management -IMT</option>
                          <option>Offline Center NEET UG</option>*/}
                          <option>MBA / MCA</option>
                          <option>M.SC / M.Com / MA</option>
                          <option>BBA / BCA</option>
                          <option>B.SC / B.Com / BA</option>
                          <option>MICA - Advance Certificate in Digital Marketing and Communication</option>
                          <option>OP Jindal MBA</option>
                          <option>Job Linked - Advance general management -IMT</option>
                          <option>Job Linked - Full Stack Developer Boot Camp </option>
                          <option>BIMTECH Post Graduate Diploma</option>
                          <option>IITB Executive PG Programme in Data Science</option>
                          <option>Job Linked - IITB Certificate Programme in Data Science</option>
                          <option>Chandigarh University</option>
                      </select>
</div>
                
                
                   
            {/*   
                <div className="sabel">
              <label>Sub Goal*</label>
                      <select id="txtSubGoal" name="location" placeholder="Anycity" required>
                      <option></option>
                      
                      </select>
  </div>*/}

                    
<div className="sabel">
              <label>Select Prefer time of call*</label>
                      <select id="txtSubGoal" name="location" placeholder="Anycity" required>
                      <option></option>
                      <option value="11AM to 1 PM">11AM to 1 PM</option>
                      <option value="1 PM to 2.30 PM">1 PM to 2.30 PM</option>
                      <option value="2.30 PM to 5 PM">2.30 PM to 5 PM</option>
                      <option value="5 PM to 7 PM">5 PM to 7 PM</option>
                      <option value="After 7 PM">After 7 PM</option>
                      </select>
                  </div>
                  </div>
                

                  <div className="inputbox">
                  <div className='sabel'>
                  <label>Institution Name*</label>
                  <TextField id="txtcollege" variant="standard" className='inputs' name="collegename"  value={txtcollege} required onChange={(e) => setCollege(e.target.value.replace(/[^a-z]/gi, ' '))}  />

</div>
                <div className='sabel'>
                  <label>Currently Studying In*</label>
                  <select name="academy" id="txtStatus" required>
                    <option></option>
                      <option value="1st Year">1st Year</option>
                      <option value="2nd Year">2nd Year</option>
                      <option value="3rd Year">3rd Year</option>
                      <option value="4th Year">4th Year</option>
                      <option value="5th Year">5th Year</option>
                      <option value="Class 8th">Class 8th</option>
                      <option value="Class 9th">Class 9th</option>
                      <option value="Class 10th">Class 10th</option>
                      <option value="Class 11th">Class 11th</option>
                      <option value="Class 12th">Class 12th</option>
                      <option value="Already Passed">Already Passed</option>
                    </select>
                </div>
                  

                  </div>
                  

                  <div className="inputbox">
               
                  <div className='sabel'>
                  <label>Enter your comment</label>
                    <TextField id="txtParentName" className='inputs' variant="standard"  value={txtParentName}  name="name" onChange={(e) => setParentName(e.target.value)} />
</div>
<div className='sabel'>
                  <label>Alternate Mobile Number</label>
                    <TextField id="txtParentMobile" variant="standard" className='inputs' name="mobile"  value={txtParentMobile} onChange={(e) => setParentMobile(e.target.value.replace(/[^0-9]/g, ""))} type="tel" inputProps = {{minLength:10, maxLength:10}} required />

                  </div>
                  </div>
     <div>

     </div>
            

<div className='inputbox' style={{textAlign:'justify'}}>
      <input type="checkbox" id="term" name="term" value="declare" required />
      <label htmlFor="term" className='termlabel'> By submitting this form, I hereby declare that I have attained the age of majority and that
the information given in this form is true and correct. Further, I hereby consent to NetAmbit
Valuefirst Services Pvt. Ltd. and its affiliates contacting at the contact details for marketing
communication, including in relation to offering various products and services from time to
time on behalf of its clients.</label>
</div>

              <button color="primary" >Register</button>


              <div className="inputbox inputbox2 inptbx3 block hdid">
<div>
        
        
<TextField id="txtLocation" variant="standard" className='inputs' name="location" value={txtLocation} onChange={(e) => setLocation(e.target.value.replace(/[^a-z]/gi, ''))}   />

<TextField id="txtqrCode" variant="standard" className='inputs' name="qrcoe" value={txtqrCodes} onChange={(e) => setqrCode(e.target.value.replace(/[^0-9]/g, ""))}   />
    
<TextField id="agMobile" variant="standard" className='inputs' name="mobiles"  value={agMobile} onChange={(e) => setagMobile(e.target.value.replace(/[^0-9]/g, ""))} required type="tel" inputProps = {{maxLength:10}}   />
              </div>
              </div>

              <div className="inptbx3 inptbx4 block">
               
               <div className='agent'>
     
            
            
              <TextField id="standard-basic" variant="standard" className='inputs' name="mobile" value={offdata2?.response?.objLst[0]?.influencerName} onChange={(e) => setAgent(e.target.value.replace(/[^0-9]/g, ""))} required type="tel" inputProps = {{maxLength:10}} disabled  />
              </div>
              
              <div>

<TextField id="agMobile" variant="standard" className='inputs' name="mobile"  value={agMobile} onChange={(e) => setagMobile(e.target.value.replace(/[^0-9]/g, ""))} required type="tel" inputProps = {{maxLength:10}} disabled  />
</div>


               </div>



          


          </form>


    </div>
  </div>
</section>
</div>

  );
}

export default Validcoupon;
