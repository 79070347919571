import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { render } from "react-dom";
import { BrowserRouter, Route, Link, Routes, Switch, HashRouter} from 'react-router-dom';
import Validcoupon from './coupon/Validcoupon';
import Invalidcoupon from './coupon/Invalidcoupon';
import Thankyou from './coupon/Thankyou';
import Tryagain from './coupon/Tryagain';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes> 
        <Route path="/" element={< App />} />
          <Route path="/:jvVchNo" element={< Validcoupon />} />
          <Route path="/not-found" element={< Invalidcoupon />} />
          <Route path="/thankyou" element={< Thankyou />} />
          <Route path="/tryagain" element={< Tryagain />} />
      </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
