

function Thankyou() {


    
    return (
    <>
     
     <div className="thankblock">
         <div className="tblock">
       
       <div className='thankblock2'>
    
             <div className="pop-up-content-wrap">
             <div className='logobox'>
               <img src={process.env.PUBLIC_URL + './images/logo.svg'} alt="Slide" className='logo' />
             </div>
             <img src={process.env.PUBLIC_URL + './images/Contact us-rafiki.svg'} alt="Slide" className="thankimg" />

                 
                 <p style={{fontSize:'18px'}}>Thanks for sharing your information! </p>
                 <p style={{fontWeight:'300', lineHeight:'25px'}}>
                 Our counselor will get back to you with complete program offerings based on your input. In order to make the offerings more valuable, we would like to know more about your learning needs. Click and send Hello at the link mentioned- <a href="https://wa.me/+917835020129?text=Hello" target="_blank">https://wa.me/+917835020129?text=Hello</a> 
                  </p>
               
             </div>
         </div>
     </div>
 </div>
 </>
    );
  }
  
  export default Thankyou;
  